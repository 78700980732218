<!--排名统计-->
<template>
  <div>
    <div class="t-list">
      <div class="t-top">
        <div class="t-search">
          <el-form
              ref="form1"
              :inline="true"
              :model="form1"
              :rules="rules"
              :validate-on-rule-change="false"
              @submit.native.prevent
              class="flex flex-align">
            <el-form-item prop="exam_id">
              <el-select
                  size="small"
                  v-model="form1.exam_id"
                  :popper-append-to-body="false"
                  placeholder="选择考试名称"
                  @enter="onSearch(1)"
                  @change="examChange">
                <el-option
                    v-for="item in examOptions"
                    :label="item['exam_name']"
                    :value="item['exam_id']">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="school_id">
              <el-select
                  size="small"
                  v-model="form1.school_id"
                  :popper-append-to-body="false"
                  placeholder="筛选学校"
                  @enter="onSearch(1)"
                  @change="isSearchFirst = false">
                <template v-if="schoolOptions.length">
                  <el-option label="全部选择" value="0"></el-option>
                </template>
                <el-option
                    v-for="item in schoolOptions"
                    :label="item['school_name']"
                    :value="item['school_id']"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="grade_id">
              <el-select
                  size="small"
                  v-model="form1.grade_id"
                  :popper-append-to-body="false"
                  placeholder="筛选年级"
                  @enter="onSearch(1)"
                  @change="isSearchFirst = false">
                <el-option
                    v-for="item in gradeOptions"
                    :label="item['grade_name']"
                    :value="item['grade_id']"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button debounce size="small" style="margin-right: 20rem" type="primary" @click="onSearch(1)">搜索</el-button>
            <el-button size="small" style="margin-left: 0" @click="onReset(1)">重置</el-button>
          </el-form>
        </div>
      </div>

      <div v-if="isFirstOpen" class="main-box">
        <div class="grade-type">
          <span :class="!params.subject_id ? 'active' : ''" @click="gradeTypeChange('','', 'subjectBtn')">总成绩</span>
          <span
              v-for="item in subjectOptions"
              :class="item.id === params.subject_id ? 'active' : ''"
              @click="gradeTypeChange(item.id,item.subject_name, 'subjectBtn')"
          >{{ item.subject_name }}</span>
        </div>

        <div class="t-search my-search">
          <el-form
              ref="form2"
              :model="form2"
              :rules="rules"
              :validate-on-rule-change="false"
              @submit.native.prevent>
            <div class="flex">
              <el-form-item>
                <el-select
                    @enter="onSearch(2)"
                    v-model="form2.class_id"
                    :popper-append-to-body="false"
                    placeholder="筛选班级" size="small">
                  <template v-if="teachingOptions.length">
                    <el-option label="全部选择" value="0"></el-option>
                  </template>
                  <el-option
                      v-for="item in teachingOptions"
                      :label="item['class_name']"
                      :value="item['id']"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form2.screen_id" :popper-append-to-body="false" placeholder="筛选展示条件" size="small" @enter="onSearch(2)">
                  <el-option v-for="item in conditionOptions" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <span style="width: 66rem;min-width: 66rem">筛选分数段</span>
              <div style="width: 90rem;min-width: 90rem;margin-right: 20rem">
                <el-form-item prop="min">
                  <el-input v-model="form2.min" placeholder="最低分数" type="text" @input="minChange" size="small" @enter="onSearch(2)"></el-input>
                </el-form-item>
              </div>
              <span style="width: 16rem">至</span>
              <div style="width: 90rem;min-width: 90rem;margin-right: 20rem">
                <el-form-item prop="max">
                  <el-input v-model="form2.max" placeholder="最高分数" type="text" @input="maxChange" size="small" @enter="onSearch(2)"></el-input>
                </el-form-item>
              </div>
              <div style="width: 145rem;min-width: 145rem">
                <el-form-item>
                  <el-button debounce type="primary" size="small" @click="onSearch(2)">搜索</el-button>
                  <el-button size="small" style="margin-left: 16rem" @click="onReset(2)">重置</el-button>
                </el-form-item>
              </div>
            </div>
            <div>
              <el-form-item style="margin-right: 0">
                <el-button size="small" type="primary" @click="downData">导出数据</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <template v-if="tableConfig.length">
          <list-template
              style="padding-left: 0"
              :total="total"
              :current-page="page"
              :table-data="tableData"
              :table-config="tableConfig"
              @onChangePage="changeCurrentPage"
              :loading="tableLoading"
              @onHandle="handleClick"
          ></list-template>
        </template>
      </div>

      <t-result v-else type="empty" style="margin-left: -16rem"></t-result>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"数据展示","keepAlive":true}}},
  data() {
    let verify = (rule, value, callback) => {
      if (this.form2.min && this.form2.max) {
        if (parseInt(this.form2.max) < parseInt(this.form2.min)) {
          callback(new Error(''))
        } else {
          callback();
        }
      } else callback();
    }
    return {
      // mock_url: 'https://mock.mengxuegu.com/mock/622089d4e7ee771c8c6e2148/hh',
      mock_url: '',

      isFirstOpen: false,

      //上方搜索条件是否已经搜索完毕，true为已经搜索了，false为未搜索
      isSearchFirst: false,

      // //正在使用的表格配置，从下面两个表中取其一
      tableConfig: [],
      //总成绩表格配置
      tableConfig_all: [
        {label: '总成绩排名', prop: 'ranking'},
        {label: '学生姓名', prop: 'student_name'},
        {label: '行政班', prop: 'class_name'},
        {label: '总成绩分数', prop: 'total_score'},
      ],
      //科目成绩表格配置
      tableConfig_sub: [
        {label: '成绩排名', prop: 'ranking'},
        {label: '学生姓名', prop: 'student_name'},
        {label: '教学班', prop: 'class_name'},
        {label: this.subject_name, prop: "subject_score"},
        {label: "学科得分率", prop: "scale"},
        {label: "总成绩分数", prop: "total_score"}
      ],
      tableConfig_last: [{
        prop: "",
        label: "操作",
        handle: true,
        buttons: [{ type:"view", text:"查看成绩分析" }]
      }],

      //获取的其余的学生信息的总成绩表格配置
      tableDataConfig: [],
      tableData: [],
      tableLoading: false,

      rules: {
        exam_id: [
          {required: true, message: '请选择考试名称', trigger: 'change'},
        ],
        school_id: [
          {required: true, message: '请筛选学校', trigger: 'change'},
        ],
        grade_id: [
          {required: true, message: '请筛选年级', trigger: 'change'},
        ],
        min: [
          {validator: verify, message: '请输入正确的值', trigger: 'blur'},
          {validator: this.$verify.num, message: '请输入正确的值', trigger: 'blur'}
        ],
        max: [
          {validator: verify, message: '请输入正确的值', trigger: 'blur'},
          {validator: this.$verify.num, message: '请输入正确的值', trigger: 'blur'}
        ],
      },

      examOptions: [],    //考试配置
      schoolOptions: [],    //学校配置
      gradeOptions: [],    //年级配置
      subjectOptions: [],    //科目配置
      teachingOptions: [],    //班级配置
      conditionOptions: [],    //展示条件配置
      conditionOptionsCopy: {},    //展示条件配置--缓存点
      form1: {
        exam_id: '',
        school_id: '',
        grade_id: ''
      },
      form2: {
        class_id: '',
        screen_id: '',
        min: '',
        max: ''
      },

      params: {
        subject_id: '',    //已筛选的科目id，空为总成绩
      },

      //学科名称
      subject_name: '',

      //当前页码
      page: 1,
      pageSize: 20,
      total: null,

    };
  },
  created() {
    this.getSearchConfig()
    this.$route.params.max = ''
    this.$route.params.min = ''
  },
  methods: {
    //获取查询条件
    getSearchConfig() {
      this.$_axios.post(this.mock_url + '/site/get-query-cond').then(res => {
        let data = res.data.data;

        if (data.length === 0) return;
        this.dataCont = data;
        data.forEach(item => {
          this.examOptions.push(item)
        })
      })
    },

    //选择考试名称后触发事件,e为考试id
    examChange(e) {
      this.isSearchFirst = false

      this.schoolOptions = []
      this.gradeOptions = []
      this.form1.school_id = ''
      this.form1.grade_id = ''


      let data = this.dataCont

      this.getCondition()
      for (let i = 0; i < data.length; i++) {
        if (e == data[i].exam_id) {
          this.schoolOptions = data[i].school_data
          this.gradeOptions = data[i].grade_data
          return;
        }
      }
    },

    //获取筛选展示条件
    getCondition() {
      this.$_axios.get(this.mock_url + '/site/get-exam-screen').then(res => {
        //因为后端返回来的数据很锤子，id居然是键名，所以此处需要对数据进行重装
        let data = res.data.data
        let single_screen = [],
            total_screen = [];
        Object.keys(data.single_screen).forEach(key => {
          let obj = {
            label: data.single_screen[key],
            id: key
          }
          single_screen.push(obj)
        })
        Object.keys(data.total_screen).forEach(key => {
          let obj = {
            label: data.total_screen[key],
            id: key
          }
          total_screen.push(obj)
        })
        this.conditionOptionsCopy.total_screen = total_screen;
        this.conditionOptionsCopy.single_screen = single_screen;
      })
    },


    //选择科目事件
    gradeTypeChange(e, name, type) {
      if (this.tableLoading) return;
      this.reSetBottomData()
      //当前切换科目和上一次科目类型不同话，清空展示条件框
      if (!!e !== !!this.params.subject_id) {
        this.conditionOptions = []
        this.form2.screen_id = ''
      }
      this.params.subject_id = e
      this.subject_name = name
      this.form2.class_id = ''
      //展示条件筛选配置
      this.conditionOptions = e ? this.conditionOptionsCopy.single_screen : this.conditionOptionsCopy.total_screen
      this.getClass()
      if (type === 'subjectBtn') {
        this.onSearch(2)
      }
    },

    //获取班级信息
    getClass() {
      let params = {...this.params, ...this.form1}

      if (!this.isSearchFirst) return;
      if (!this.form1.exam_id) return;

      //查询班级配置
      this.$_axios.get(this.mock_url + '/site/get-class', {params}).then(res => {
        this.teachingOptions = params.subject_id ? res.data.data.teachingClass : res.data.data.adminClass
      })
    },

    onSearch(e) {
      if (e === 1) {
        //上面的搜索
        this.$refs['form1'].validate((valid) => {
          if (valid) {
            //清空下面各种筛选条件的数据
            if (this.isFirstOpen) {
              // this.reSetBottomData()
              this.gradeTypeChange('', '')
            }

            this.page = 1
            let form1 = JSON.parse(JSON.stringify(this.form1))
            let params = JSON.parse(JSON.stringify(this.params))
            let _params = {...params, ...form1}
            _params.page = 1
            _params.pageSize = this.pageSize
            this.params = _params
            this.getData(_params)
            this.isSearchFirst = true;
            this.getClass()

            this.$_axios.get('/site/exam-subject', {
              params: {
                exam_id: this.form1.exam_id,
                subject: 1
              }
            }).then(res => {

              this.subjectOptions = res.data.data

              //组装表格配置  --  总成绩中科目的配置
              let obj_arr = []
              res.data.data.forEach(item => {
                let obj = {
                  label: item.subject_name,
                  prop: item.id
                }
                obj_arr.push(obj)
              })
              this.tableDataConfig = obj_arr
            })

            //默认将总成绩的展示条件赋值过去
            this.conditionOptions = [...this.conditionOptionsCopy.total_screen]

          } else {
            return false;
          }
        });
      } else if (e === 2) {
        //下面的搜索

        this.$refs['form1'].validate((valid) => {
          if (valid) {

            this.$refs['form2'].validate((c_valid) => {
              if (c_valid) {

                if (!this.isSearchFirst) {
                  this.$message({
                    message: '请先点击上方搜索按钮！',
                    type: 'warning'
                  });
                  return false;
                }
                this.page = 1
                let params = JSON.parse(JSON.stringify(this.params))
                let form1 = JSON.parse(JSON.stringify(this.form1))
                let form2 = JSON.parse(JSON.stringify(this.form2))
                let _params = {...params, ...form1, ...form2}
                _params.page = 1
                _params.pageSize = this.pageSize
                this.params = _params

                // return;
                this.getData(_params)
              } else {
                return false;
              }
            });
          } else {
            return false;
          }
        });
      } else {
        //页码的搜索
        let params = this.params
        this.getData(params)
      }
    },

    //2个搜索按钮和页码的搜索

    //获取表格数据
    getData(params) {
      this.tableLoading = true
      this.isFirstOpen = true;
      this.$_axios.get('/ranking/list', {params}).then(res => {
        this.tableLoading = false
        //组装表格配置
        let id = this.params.subject_id
        if (id) {
          this.tableConfig_sub.forEach(item => {
            if (item.prop === 'subject_score') {
              item.label = this.subject_name
            }
          })
          this.tableConfig = [...this.tableConfig_sub, ...this.tableConfig_last]
        } else {
          this.tableConfig = [...this.tableConfig_all, ...this.tableDataConfig, ...this.tableConfig_last]
        }

        this.tableData = res.data.data
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },

    onReset(e) {
      if (e === 1) {
        //上面的重置
        this.schoolOptions = []
        this.gradeOptions = []
        this.subjectOptions = []
        this.teachingOptions = []
        this.conditionOptions = []
        this.tableConfig = []
        this.total = null
        this.isSearchFirst = false
        for (let key in this.form1) {
          this.form1[key] = ''
        }
        this.tableData = []
        this.isAllSchoolSelected = false
        this.$refs['form1'].resetFields();
        if (this.isFirstOpen) {
          this.reSetBottomData()
        }
      } else {
        //下面的重置
        this.onSearch(1)
      }
    },

    //清空下面的数据
    reSetBottomData() {
      this.page = 1
      for (let key in this.form2) {
        this.form2[key] = ''
      }
      this.params = {subject_id: ''}
      //别忘了清空之前存在params里的东西，它们之前是用来验证数据用的
      this.$route.params.min = ''
      this.$route.params.max = ''

      this.$refs['form2'].resetFields();
    },

    //查看成绩分析
    handleClick(row) {

      this.$router.push({
        path: '/edu/edu-info/student-manager/achievement-analysis',
        query: {
          student_no: row.student_no,
          exam_id: row.exam_id,
          subject_id: this.params.subject_id,
        }
      })
    },

    //切换页码
    changeCurrentPage(e) {
      this.page = e;
      this.params.page = e
      this.onSearch()
    },


    minChange(e) {
      this.$refs['form2'].clearValidate()
      this.$route.params.min = e;
    },
    maxChange(e) {
      this.$refs['form2'].clearValidate()
      this.$route.params.max = e
      // this.$refs['form2'].clearValidate(['min','max']);
    },

    //导出数据
    downData() {
      if (this.tableData.length === 0) {
        this.$message({
          message: '当前表格暂无数据！',
          type: 'warning'
        });
        return false;
      }
      let params = JSON.parse(JSON.stringify(this.params))
      delete params.page

      this.$_axios.post(this.mock_url + '/ranking/score-export', params, {responseType: "blob"}).then(res => {

        const filename = '考试排名统计' + '.xlsx'
        const blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = decodeURIComponent(filename)
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      })
    }

  },
};
</script>

<style lang="scss" scoped>
.grade-type {
  display: flex;
}

.t-list {
  padding: 20rem 0 0 32rem;

  .t-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40rem;

    .t-search {
      display: flex;

      ::v-deep .el-form-item {
        margin-right: 20rem !important;
      }

      ::v-deep .el-input {
        width: 100% !important;
      }

      .el-button {
        margin-bottom: 20rem;
      }
    }
  }

  .t-handle {
    margin-right: 15rem;
  }

  .t-handle:last-child {
    margin-right: 0;
  }

  .t-pagination {
    margin-top: 50rem;
  }

  .main-box {
    margin-right: 40rem;
    padding: 20rem;
    border: 1rem solid #ccc;

    .grade-type {
      display: flex;
      flex-wrap: nowrap;
      border-radius: 10rem;
      overflow: hidden;

      span {
        cursor: pointer;
        width: 120rem;
        height: 36rem;
        line-height: 36rem;
        color: #666;
        font-size: 14rem;
        text-align: center;
        transition: 0.2s;
        position: relative;
        background-color: #fafafa;

        &:hover {
          background-color: #e9edfe;
        }

        &.active {
          background-color: #1C4EFD;
          color: #fff;

          &:after {
            visibility: hidden;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: -5rem;
          right: 0;
          width: 1rem;
          height: 44rem;
          background-color: #ccc;
          transform: scale(.5);
        }

        &:last-child:after {
          display: none;
        }

        &:last-child {
          border-radius: 0 10rem 10rem 0;
        }
      }
    }
  }
}

.my-search {

  .el-form {
    display: flex;
    justify-content: space-between;

    .el-form-item {
      margin-right: 20rem;
    }
  }

  .t-input {
    margin-top: 20rem;
  }

  .el-select,
  ::v-deep .el-form-item__content,
  .el-form-item {
    width: 100%;
  }

  ::v-deep.el-input {
    width: auto !important;
  }

  .el-col {
    margin-right: 20rem;
  }

  span {
    font-size: 13rem;
    color: #333;
    width: 68rem;
    line-height: 40rem;
    margin-right: 20rem;
    float: left;
    margin-top: 20rem;;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-top: 20rem;
    float: left;
  }
}

::v-deep .t-table {
  margin-right: 0 !important;
}
</style>
